<template>
    <!--借阅审批-->
    <div class="page">
        <el-form size="small" :inline="true" class="query-form" ref="searchForm" @submit.native.prevent @keyup.enter.native="refreshList(1)">
            <el-form-item label="关键字：">
                <el-input size="small" v-model="srhStr" maxlength="50" placeholder="请输入藏品名称或资源名称" clearable></el-input>
            </el-form-item>
            <el-form-item>
                <el-button type="primary" @click="refreshList(1)" size="small" icon="el-icon-search">查询</el-button>
                <el-button icon="el-icon-refresh-right" @click="reset()">重置</el-button>
            </el-form-item>
            <el-form-item class="f_r" v-if="activeName == 2">
                <el-button icon="el-icon-circle-check" type="primary" size="small"
                           @click="audit(null, 2)" v-show="hasPermission('admin:borrowingApproval:adopt')">批量通过</el-button>
                <el-button icon="el-icon-circle-close" type="danger" size="small" v-show="hasPermission('admin:borrowingApproval:return')"
                           @click="audit(null, 1)">批量驳回</el-button>
            </el-form-item>
        </el-form>
        <div class="bg-white">
            <el-tabs v-model="activeName" @tab-click="handleClick">
                <el-tab-pane label="待审批" name="2"></el-tab-pane>
                <el-tab-pane label="审批历史" name="3"></el-tab-pane>
            </el-tabs>
            <el-table
                    :data="dataList"
                    v-loading="loading"
                    size="small"
                    height="calc(100vh - 350px)"
                    @selection-change="selectionChangeHandle"
                    class="table">
                <el-table-column
                        type="selection"
                        header-align="center"
                        align="center"
                        width="50">
                </el-table-column>
                <el-table-column prop="exhibitionId" label="图片" width="110">
                    <template slot-scope="scope">
                        <el-image fit="contain" :src="scope.row.thumb_path" class="itemImg"
                                  style="width: 100px; height: 100px;">
                            <el-image slot="placeholder" fit="contain" class="itemImg"
                                      :src="require('@/assets/img/thumbnail.png')" style="width: 100px; height: 100px"></el-image>
                            <el-image slot="error" fit="contain" class="itemImg"
                                      :src="require('@/assets/img/thumbnail.png')" style="width: 100px; height: 100px"></el-image>
                        </el-image>
                    </template>
                </el-table-column>
                <el-table-column show-overflow-tooltip prop="general_num" label="藏品编号">
                    <template slot-scope="scope">
                        {{ scope.row.general_num ? scope.row.general_num : scope.row.help_num }}
                    </template>
                </el-table-column>
                <el-table-column show-overflow-tooltip prop="collection_name" label="藏品名称" width="90" ></el-table-column>
                <el-table-column show-overflow-tooltip prop="resource_name" label="资源名称" width="90"></el-table-column>
                <el-table-column
                        prop="column_name"
                        label="类别" show-overflow-tooltip>
                </el-table-column>
                <el-table-column
                        prop="end_format_time"
                        label="开始时间" show-overflow-tooltip width="180">
                </el-table-column>
                <el-table-column
                        prop="start_format_time"
                        label="结束时间" show-overflow-tooltip width="180">
                </el-table-column>
                <el-table-column
                        prop="userName"
                        label="借阅人" show-overflow-tooltip>
                </el-table-column>
                <el-table-column
                        prop="remarks"
                        label="借阅目的" show-overflow-tooltip>
                </el-table-column>
                <el-table-column
                        prop="state"
                        label="审核状态" show-overflow-tooltip>
                    <template slot-scope="scope">
                        {{$dictUtils.getDictLabel("apply_status", scope.row.apply_status ,'')}}
                    </template>
                </el-table-column>
                <el-table-column prop="returnStatus" width="120" label="归还状态" show-overflow-tooltip>
                    <template slot-scope="scope">
                        <span v-if="scope.row.apply_status == 2">{{scope.row.on_expire ? '已归还': '未归还'}}</span>
                    </template>
                </el-table-column>
                <el-table-column
                        fixed="right"
                        width="200"
                        label="操作">
                    <template slot-scope="scope">
                        <el-button type="text" size="small" v-show="hasPermission('home:details:view')"
                                   @click="view(scope.row)">详情</el-button>
                        <el-button type="text" size="small" @click="audit(scope.row, 2)" v-if="activeName == 2 && hasPermission('admin:borrowingApproval:adopt')">通过</el-button>
                        <el-button type="text" size="small" @click="audit(scope.row, 1)" v-if="activeName == 2 && hasPermission('admin:borrowingApproval:return')">驳回</el-button>
                    </template>
                </el-table-column>
            </el-table>
            <el-pagination
                    @size-change="sizeChangeHandle"
                    @current-change="currentChangeHandle"
                    :current-page="pageNo"
                    :page-sizes="[10, 20, 50, 100]"
                    :page-size="pageSize"
                    :total="total"
                    background
                    layout="total, sizes, prev, pager, next, jumper">
            </el-pagination>
        </div>
    </div>
</template>

<script>
    export default {
        data() {
            return {
                srhStr: '',
                activeName: '2',
                dataList: [],
                pageNo: 1,
                pageSize: 10,
                total: 0,
                dataListSelections: [],
                loading: false,
            }
        },
        created() {
            let srhData = JSON.parse(sessionStorage.getItem('borrowApprovalOpt'))
            if(srhData != null && srhData) {
                this.activeName = srhData.activeName
                this.srhStr = srhData.srhStr
                this.pageNo = parseInt(srhData.pageNo);
            }
            sessionStorage.removeItem('borrowApprovalOpt')
        },
        mounted() {
            this.refreshList()
        },
        methods: {
            refreshList(type) {
                if(type == 1) {
                    this.pageNo = 1
                }
                this.loading = true
                this.$axios(this.api.rcPage.homeBorrowingList, {
                    'current': this.pageNo,
                    'size': this.pageSize,
                    'name': this.srhStr,
                    'type': this.activeName
                }, 'get').then(res => {
                    this.loading = false
                    if (res.status) {
                        this.total = parseInt(res.data.total);
                        this.dataList = res.data.records;
                        if (this.dataList.length == 0 && this.pageNo > 1) {
                            this.pageNo--
                            this.refreshList()
                        }
                    }

                })
            },
            reset() {
                this.srhStr = ''
                this.refreshList(1)
            },
            handleClick(val) {
                this.type = val.name
                this.refreshList(1)
            },
            // 详情
            view(row) {
                let opt = {
                    activeName: this.activeName,
                    srhStr: this.srhStr,
                    pageNo: this.pageNo,
                }
                sessionStorage.setItem('borrowApprovalOpt', JSON.stringify(opt))
                var newRow = row
                newRow.id = newRow.foreign_key_id
                sessionStorage.setItem('dizhi','/dyyg/home/borrowApproval')
                this.$router.push({
                    path: '/dyyg/home/retrievedDetails',
                    query: newRow
                });
            },
            // 通过、驳回
            audit(row, type) {
                if (!row && !this.dataListSelections.length) {
                    this.$message.warning('请至少选择一条数据')
                    return
                }
                let root = []
                if(!row) {
                    root = this.dataListSelections.map(item => {
                        return {
                            id: item.id,
                            applyStatus: type,
                            endTime: item.end_format_time,
                            loanDays: item.loan_days,
                        }
                    })
                } else{
                    root = [{
                        id: row.id,
                        applyStatus: type,
                        endTime: row.end_format_time,
                        loanDays: row.loan_days,
                    }];
                }
                let text = type == 2 ? '通过' : '驳回'
                this.$confirm('确定'+text+'所选项吗?', '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'
                }).then(() => {
                    this.$axios(this.api.rcPage.homeBorrowingUpdateById, root, 'post').then(data => {
                        if (data && data.status) {
                            this.$message.success(text + '成功')
                            this.refreshList()
                        } else {
                            this.$message.error(text + '失败')
                        }
                    })
                })
            },
            // 多选
            selectionChangeHandle(val) {
                this.dataListSelections = val
            },
            // 每页数
            sizeChangeHandle(val) {
                this.pageSize = val
                this.pageNo = 1
                this.refreshList()
            },
            // 当前页
            currentChangeHandle(val) {
                this.pageNo = val
                this.refreshList()
            },
        }
    }
</script>

<style scoped>

</style>
